
import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import axios from "axios";
import Footer from "../components/common/Footer";

import Spacer from "../components/layout/Spacer";

import { motion } from "framer-motion";

const TahnksEng = () => {
  
  
  return (
    
      <Wrapper
        initial={{opacity: 0, y: 50}}
        animate={{opacity: 1, y: 0}}
        transition={{duration: 1}}
        exit={{opacity: 0, y: 50}}
      >
        
        <div className="column" id="contact">
          <p className="title">Submit completely</p>
        </div>
        <Spacer height={"80px"} />
        <p className="contact_greeting">
                        Thank you for contacting us.<br/>
                        Our staff will get back to you as soon as possible.</p>
        
        <Spacer height={"200px"} />
        <Footer />
      </Wrapper>
    
  );
};

const Wrapper = styled(motion.div)`
  
  justify-content: center;
  width: 100%;

  .title {
    padding-top: 68px;
    font-size: 32px;
    line-height: 44px;
  }

  .column {
    width: 100%;
    max-width: 1280px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .contact_greeting {
    text-align: center;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0.05em;
    line-height: 200%;

    color: #000000;
  }

  .contents {
    width: 100%;
    padding: 70px 282px 0px 109px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
   
  }

`;

export default TahnksEng;
